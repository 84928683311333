import './App.css';

function App() {
  return (
    <div>
      <div className="bg-red-600">
        <svg
          width="100%"
          height="300px"
          viewBox="0 0 1920 1080"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            fillRule: 'evenodd',
            clipRule: 'evenodd',
            strokeLinejoin: 'round',
            strokeMiterlimit: 2,
          }}
        >
          <g transform="matrix(1,0,0,1,181.574,14.851)">
            <g transform="matrix(293.714,0,0,293.714,1331.94,354.775)"></g>
            <text
              x="237.25px"
              y="354.775px"
              style={{
                fontFamily: "'AgencyFB-Reg', 'Agency FB', sans-serif",
                fontStretch: 'condensed',
                fontSize: '293.714px',
                fill: 'rgb(255,255,255)',
              }}
            >
              THE BRAZEN
            </text>
          </g>
          <g transform="matrix(1.02761,0,0,1,345.932,-27.2269)">
            <g transform="matrix(461.879,0,0,461.879,1127.33,770.604)"></g>
            <text
              x="48.185px"
              y="770.604px"
              style={{
                fontFamily: "'AgencyFB-Reg', 'Agency FB', sans-serif",
                fontStretch: 'condensed',
                fontSize: '461.879px',
                fill: 'rgb(255,255,255)',
              }}
            >
              LEFTIST
            </text>
          </g>
          <g transform="matrix(1,0,0,1,0,-51.9786)">
            <rect
              x="424.417"
              y="815.982"
              width="1071.17"
              height="40.428"
              style={{
                fill: 'rgb(255,255,255)',
              }}
            />
          </g>
        </svg>
      </div>

      <div className="container max-w-lg mx-auto">
        <br />
        <br />
        <a
          className="underline font-bold text-lg text-red-700 hover:cursor-pointer"
          href="https://twitter.com/BrazenLeftist"
        >
          Twitter
        </a>
        <br />
        <a
          className="underline font-bold text-lg text-red-700 hover:cursor-pointer"
          href="http://patreon.com/thebrazenleftist"
        >
          Patreon
        </a>
      </div>
    </div>
  );
}

export default App;
